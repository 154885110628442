<template>
    <div>
        <div v-if="!itData.readOnly" class="itinerary-bar p-2 d-flex justify-content-between" style="position: sticky;">
            <div>
                <button
                        @click="$emit('undo')"
                        :disabled="undoIndex < 1"
                        class="btn btn-warning"
                        v-tooltip='$t("Itinerary.builder.detail.undo")'
                >
                    <i class="fa fa-undo"></i>
                </button>
                <button
                        @click="$emit('redo')"
                        :disabled="undoIndex === -1 || undoIndex >= historySize - 1"
                        class="btn btn-warning ml-2"
                        v-tooltip='$t("Itinerary.builder.detail.redo")'
                >
                    <i class="fa fa-redo"></i>
                </button>
            </div>
            <div>
                <button v-if="!isCover && activeSectionId !== null"
                        class="btn btn-primary"
                        @click="openAddItem(false)">
                    <i class="fa fa-plus-circle"></i> {{$t("Itinerary.builder.add_item")}}
                </button>
                <button v-if="droppable && activeSectionId !== null"
                        class="btn btn-secondary ml-2"
                        @click="showReorderModal = true">
                    <i class="fa fa-bars"></i> {{$t("Itinerary.builder.reorder_items")}}
                </button>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2 d-none d-md-block">
                <div class="list-group itinerary-section-list">
                    <!--<a v-if="!hasCoverSection && !itData.readOnly"
                       class="list-group-item list-group-item-action"
                       href="#"
                       @click.prevent="addCoverSection"
                    >
                        <i class="fa fa-plus-circle"></i> {{$t("Itinerary.builder.add_cover")}}
                    </a>
                    <a v-if="!itData.readOnly"
                       class="list-group-item list-group-item-action"
                       href="#"
                       @click.prevent="addTopSection"
                    >
                        <i class="fa fa-plus-circle"></i> {{$t("Itinerary.builder.add_section")}}
                    </a>
                    <a v-if="!hasAllDays && !itData.readOnly" class="list-group-item list-group-item-action"
                       href="#"
                       @click.prevent="addDaySection"
                    >
                        <i class="fa fa-plus-circle"></i> {{$t("Itinerary.builder.add_day_section")}}
                    </a>
                    -->
                    <a v-if="!itData.readOnly" class="list-group-item list-group-item-action"
                       href="#"
                       @click.prevent="addAdditionalDaySection"
                    >
                        <i class="fa fa-plus-circle"></i> {{$t("Itinerary.builder.add_day_section")}}
                    </a>
                    <a v-for="section in sectionList" :key="section.id"
                       :class="{'list-group-item-primary' : activeSection !== null && section.id === activeSection.id}"
                       href="#"
                       @click.prevent="openSection(section)"
                       v-tooltip="section.title"
                       class="list-group-item list-group-item-action">
                        <div style="display:table;width:100%;">
                            <div style="display:table-cell;">
                                <div style="max-width:110px;text-overflow: ellipsis;white-space:nowrap;overflow: hidden;">
                                    {{getShortSectionTitle(section)}}
                                </div>
                            </div>
                            <div style="display:table-cell;width:20px;">
                                <i class="fa fa-plane" v-if="sectionHasFlight(section)"
                                   v-tooltip='$t("Itinerary.builder.flight")'
                                ></i>
                            </div>
                            <div style="display:table-cell;width:16px;">
                                <i class="fa fa-h-square" v-if="sectionHasSleepCheckout(section) || sectionHasSleep(section) || sectionHasSleepMid(section)"
                                   v-tooltip='$t("v2.Itinerary.defaults.ITEM_NAME_SLEEP")'
                                ></i>
                            </div>
                            <div style="display:table-cell;width:16px;">
                                <i class="fa fa-car" v-if="sectionHasRentalcar(section) || sectionHasRentalcarReturn(section) || sectionHasRentalcarPickup(section)"
                                   v-tooltip='$t("Itinerary.builder.car_rental")'
                                ></i>
                            </div>
                            <!--<div style="display:table-cell;text-align: right;" v-if="sectionHasSleepCheckout(section)"
                                 v-tooltip='$t("Itinerary.builder.checkout")'>
                                <i class="fa fa-h-square" style="color:orange;"></i>
                            </div>
                            <div style="display:table-cell;text-align: right;" v-if="sectionHasRentalcarReturn(section)"
                                 v-tooltip='$t("Itinerary.builder.car_rental_return")'>
                                <i class="fa fa-car" style="color:orange;"></i>
                            </div>
                            <div style="display:table-cell;text-align: right;" v-if="sectionHasFlight(section)"
                                 v-tooltip='$t("Itinerary.builder.flight")'>
                                <i class="fa fa-plane"></i>
                            </div>
                            <div style="display:table-cell;text-align: right;" v-if="sectionHasRentalcarPickup(section)"
                                 v-tooltip='$t("Itinerary.builder.car_rental_pickup")'>
                                <i class="fa fa-car" style="color:green;"></i>
                            </div>
                            <div style="display:table-cell;text-align: right;" v-if="sectionHasRentalcar(section)"
                                 v-tooltip='$t("Itinerary.builder.car_rental")'>
                                <i class="fa fa-car"></i>
                            </div>
                            <div style="display:table-cell;text-align: right;" v-if="sectionHasSleep(section)"
                                 v-tooltip='$t("Itinerary.builder.checkin")'>
                                <i class="fa fa-h-square" style="color:green;"></i>
                            </div>
                            <div style="display:table-cell;text-align: right;" v-if="sectionHasSleepMid(section)">
                                <i class="fa fa-h-square"></i>
                            </div>-->
                        </div>
                    </a>
                    <!--<a v-if="!itData.readOnly"
                       class="list-group-item list-group-item-action"
                       href="#"
                       @click.prevent="addBottomSection"
                    >
                        <i class="fa fa-plus-circle"></i> {{$t("Itinerary.builder.add_section")}}
                    </a>
                    <a v-if="!itData.readOnly" class="list-group-item list-group-item-action"
                       href="#"
                       @click.prevent="addDaySection"
                    >
                        <i class="fa fa-plus-circle"></i> {{$t("Itinerary.builder.add_day_section")}}
                    </a>-->
                    <a v-if="!itData.readOnly" class="list-group-item list-group-item-action"
                       href="#"
                       @click.prevent="addAdditionalDaySection"
                    >
                        <i class="fa fa-plus-circle"></i> {{$t("Itinerary.builder.add_day_section")}}
                    </a>
                </div>
            </div>
            <div class="col-md-10 col-sm-12">
                <div class="card itinerary-print" v-if="activeSection !== null">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-8">
                                <h3>
                                    <input v-if="!itData.readOnly"
                                           type="text" class="name-input"
                                           v-model="activeSection.title"
                                           @blur="emitAutosave"
                                           v-tooltip='$t("Itinerary.builder.click_to_change_title")'
                                    />
                                    <span v-else>{{activeSection.title}}</span>
                                </h3>
                            </div>
                            <div v-if="!itData.readOnly" class="col-4 d-flex justify-content-end align-items-center">
                                <!-- <el-switch
                                        class="pr-2"
                                        v-model="activeSection.showPdfTitle"
                                        :active-text='$t("Itinerary.builder.show_title_on_pdf")'
                                        @change="emitAutosave"
                                ></el-switch> -->
                                <div class="btn-group dropleft" v-if="activeSection.type !== 'COVER' && activeSection.type !== 'OTHER'">
                                    <button v-if="activeSection.type!=='COVER'"
                                            class="btn btn-primary"
                                            @click="showLoadSectionFromLibrary = true"
                                            v-tooltip='$t("Itinerary.general.load_from_library")'
                                    >
                                        <i class="fa fa-book-open"></i>
                                    </button>
                                    <button v-if="activeSection.type!=='COVER'"
                                            class="btn btn-primary"
                                            @click="showSetNameModal = true"
                                            v-tooltip='$t("Itinerary.general.save_to_library")'
                                    >
                                        <i class="fa fa-save"></i>
                                    </button>
                                    <button v-if="activeSection.type!=='COVER'"
                                            class="btn btn-danger"
                                            @click="deleteActiveSection" v-tooltip='$t("Itinerary.builder.remove_section")'>
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="activeSection.type === 'DAY'">
                            <div class="col-4">
                                <el-switch
                                    :disabled="itData.readOnly"
                                    class="pr-2"
                                    v-model="activeSection.isCustomTabName"
                                    :active-text='$t("Itinerary.builder.custom_tab_name")'
                                    @change="emitAutosave"
                                ></el-switch>
                            </div>
                            <div class="col-4">
                                <input v-if="activeSection.isCustomTabName"
                                    :readonly="itData.readOnly"
                                    type="text"
                                    class="name-input"
                                    style="border-color: rgb(206, 212, 218); background-color: white; border-radius: 4px; border-style: solid; border-width: 1px;"
                                    v-model="activeSection.customTabName"
                                    @blur="emitAutosave"
                                    v-tooltip='$t("Itinerary.builder.click_to_change_title")'
                                />
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="list-group">
                            <li class="list-group-item" v-if="activeSection.showPdfTitle">
                                <h2>
                                    <input type="text" class="name-input"
                                           v-model="activeSection.title"
                                           @blur="emitAutosave"
                                           v-tooltip='$t("Itinerary.builder.click_to_change_title")'
                                    />
                                </h2>
                            </li>
                                <li class="list-group-item list-item-summernote"
                                    v-for="item in activeSection.items"
                                    :key="item.id">
                                    <trip-generic-item
                                            v-if="item.type === 'GENERIC'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-generic-item>
                                    <trip-cover
                                            v-if="item.type === 'COVER'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :itin-id="itData.id"
                                            :start-date="itData.startDate"
                                            :end-date="itData.endDate"
                                            :droppable="droppable"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-cover>
                                    <trip-tour-name
                                            v-if="item.type === 'TOUR_NAME'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-tour-name>
                                    <trip-client-info
                                            v-if="item.type === 'CLIENT_INFO'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-client-info>
                                    <trip-highlights
                                            v-if="item.type === 'TRIP_HIGHLIGHTS'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                            :droppable="droppable"
                                    >
                                    </trip-highlights>
                                    <trip-ratings
                                            v-if="item.type === 'TRIP_RATINGS'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                            :droppable="droppable"
                                    >
                                    </trip-ratings>
                                    <trip-faq
                                            v-if="item.type === 'TRIP_FAQ'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                            :droppable="droppable"
                                    >
                                    </trip-faq>
                                    <trip-url
                                            v-if="item.type === 'TRIP_URL'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-url>
                                    <trip-soundcloud
                                            v-if="item.type === 'TRIP_SOUNDCLOUD'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-soundcloud>
                                    <trip-video
                                            v-if="item.type === 'TRIP_VIDEO'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                            :droppable="droppable"
                                    >
                                    </trip-video>
                                    <trip-description
                                            v-if="item.type === 'TRIP_DESCRIPTION'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                            :droppable="droppable"
                                    >
                                    </trip-description>
                                    <trip-dates
                                            v-if="item.type === 'TRIP_DATES' && itData.startDate && itData.endDate"
                                            :editable="!itData.readOnly"
                                            :start-date="itData.startDate"
                                            :end-date="itData.endDate"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-dates>
                                    <trip-company-info
                                            v-if="item.type === 'COMPANY_INFO'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :manager-id.sync="itData.managerId"
                                            :manager-name.sync="itData.managerName"
                                            :sections="sectionList"
                                            :status="itData.status"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-company-info>
                                    <trip-destination-info
                                            v-if="item.type === 'DESTINATION_INFO'"
                                            :item.sync="item"
                                            :editable="!itData.readOnly"
                                            :sections="sectionList"
                                            :status="itData.status"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                            :droppable="droppable"
                                    >
                                    </trip-destination-info>
                                    <trip-poi
                                            v-if="item.type === 'POI'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-poi>
                                    <trip-activity
                                            v-if="item.type === 'ACTIVITY'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            :status="itData.status"
                                            :currency="itData.currencyCode"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-activity>
                                    <trip-transportation
                                            v-if="item.type === 'TRANSPORTATION'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-transportation>
                                    <trip-flight
                                            v-if="item.type === 'FLIGHT'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-flight>
                                    <trip-car-rental
                                            v-if="item.type === 'CAR_RENTAL'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-car-rental>
                                    <trip-car-rental-mid
                                            v-if="item.type === 'CAR_RENTAL_MID'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-car-rental-mid>
                                    <trip-car-rental-last
                                            v-if="item.type === 'CAR_RENTAL_LAST'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-car-rental-last>
                                    <trip-meal
                                            v-if="item.type === 'MEAL'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-meal>
                                    <trip-sleep
                                            v-if="item.type === 'SLEEP'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-sleep>
                                    <trip-sleep-night
                                            v-if="item.type === 'SLEEP_MID'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-sleep-night>
                                    <trip-sleep-checkout
                                            v-if="item.type === 'SLEEP_LAST'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-sleep-checkout>
                                    <trip-place
                                            v-if="item.type === 'PLACE'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-place>
                                    <trip-know-before-go
                                            v-if="item.type === 'KNOW_BEFORE_GO'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                            :droppable="droppable"
                                    >
                                    </trip-know-before-go>
                                    <trip-price
                                            v-if="item.type === 'PRICE'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :trip-type="itData.tripType"
                                            :currency="itData.currencyCode"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-price>
                                    <trip-whats-included
                                            v-if="item.type === 'WHATS_INCLUDED'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                            :droppable="droppable"
                                    >
                                    </trip-whats-included>
                                    <trip-next-steps
                                            v-if="item.type === 'NEXT_STEPS'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-next-steps>
                                    <trip-cancelation-policy
                                            v-if="item.type === 'CANCELATION_POLICY'"
                                            :editable="!itData.readOnly"
                                            :item.sync="item"
                                            :sections="sectionList"
                                            @move-item="changeItemSection($event)"
                                            @delete-item="deleteItem($event)"
                                    >
                                    </trip-cancelation-policy>
                                    <trip-carousel
                                        v-if="item.type === 'CAROUSEL'"
                                        :editable="!itData.readOnly"
                                        :item.sync="item"
                                        :sections="sectionList"
                                        @move-item="changeItemSection($event)"
                                        @delete-item="deleteItem($event)"
                                    >
                                    </trip-carousel>
                                    <trip-audio
                                        v-if="item.type === 'AUDIO'"
                                        :editable="!itData.readOnly"
                                        :item.sync="item"
                                        :sections="sectionList"
                                        @move-item="changeItemSection($event)"
                                        @delete-item="deleteItem($event)"
                                    ></trip-audio>
                                    <trip-video-file
                                        v-if="item.type === 'VIDEO_FILE'"
                                        :editable="!itData.readOnly"
                                        :item.sync="item"
                                        :sections="sectionList"
                                        @move-item="changeItemSection($event)"
                                        @delete-item="deleteItem($event)"
                                    ></trip-video-file>
                                </li>
                            <!--</draggable>-->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <v-button :loading="loading" @click.native="updateSettings" class="btn btn-primary float-right"><i class="fa fa-save" aria-hidden="true"></i>&nbsp;Save</v-button>
            </div>
        </div>-->
        <builder-add-day-section-modal
                :show.sync="showAddDaySectionModal"
                :it-data="itData"
                @add="doAddDaySection($event)"
        >
        </builder-add-day-section-modal>
        <builder-add-item-modal
                :show.sync="showAddItemModal"
                :it-data="itData"
                @add-item="addItem($event)"
                :only="activeSection && activeSection.type === 'OTHER' ? ['AUDIO'] : null"
                :hideLibrary="activeSection && activeSection.type === 'OTHER'"
        >
        </builder-add-item-modal>
        <change-dates-modal
                :show.sync="showChangeDatesModal"
                :item="changeDatesItem"
                @update-days="updateItemDays($event)"
                @update-dates="updateItemDates($event)"
        >
        </change-dates-modal>
        <reorder-section-items-modal
                :show.sync="showReorderModal"
                :section="activeSection"
        >
        </reorder-section-items-modal>

        <save-section-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="activeSection"
        >
        </save-section-to-library-modal>
        <LoadSectionFromLibraryModal
                :show.sync="showLoadSectionFromLibrary"
                :item.sync="activeSection"
                @add-section="addSetion($event)"
        >
        </LoadSectionFromLibraryModal>
        <!--<move-item-modal
                :show.sync="showMoveItemModal"
                :item="moveItem"
                :sections="sectionList"
                @move-item="changeItemSection($event)"
        >
        </move-item-modal>-->
    </div>
</template>

<script>
    import moment from 'moment';
    import draggable from 'vuedraggable'
    import {copyData, generateUUID, showCustomDialog, showHelpDialog, showErrorDialog, showSuccess} from "../../utils";
    import bootbox from 'bootbox';

    import ChangeDatesModal from "./modal/ChangeDatesModal";
    import vButton from '../../components/VButton';
    import BuilderAddItemModal from './modal/BuilderAddItemModal';
    import TripCover from './item/TripCover';
    import TripActivity from './item/TripActivity';
    import TripCarRental from './item/TripCarRental';
    import TripCarRentalMid from './item/TripCarRentalMid';
    import TripCarRentalLast from './item/TripCarRentalLast';
    import TripClientInfo from './item/TripClientInfo';
    import TripCompanyInfo from './item/TripCompanyInfo';
    import TripDescription from './item/TripDescription';
    import TripDestinationInfo from './item/TripDestinationInfo';
    import TripFlight from './item/TripFlight';
    import TripGenericItem from './item/TripGenericItem';
    import TripHighlights from './item/TripHighlights';
    import TripRatings from './item/TripRatings';
    import TripFaq from './item/TripFAQ';
    import TripUrl from './item/TripUrl';
    import TripSoundcloud from './item/TripSoundcloud';
    import TripVideo from './item/TripVideo';
    import TripMeal from './item/TripMeal';
    import TripPoi from './item/TripPoi';
    import TripPlace from './item/TripPlace';
    import TripSleep from './item/TripSleep';
    import TripSleepNight from './item/TripSleepNight';
    import TripSleepCheckout from './item/TripSleepCheckout';
    import TripTourName from './item/TripTourName';
    import TripTransportation from './item/TripTransportation';
    import TripKnowBeforeGo from './item/TripKnowBeforeGo';
    import TripPrice from './item/TripPrice';
    import TripWhatsIncluded from './item/TripWhatsIncluded';
    import TripNextSteps from './item/TripNextSteps';
    import TripCancelationPolicy from './item/TripCancelationPolicy';
    import TripCarousel from './item/TripCarousel';
    import TripAudio from './item/TripAudio.vue';
    import TripVideoFile from './item/TripVideoFile.vue';
    import { itineraryEventBus } from '../../event-bus';
    import * as template from "./itinerary-templates";
    import {ITEM_TYPE_SLEEP} from "./itinerary-templates";
    import {getDateMomentForDayNum, parseMoment, formatDate} from "../../date-utils";
    import {SLEEP_MID_ITEM} from "./itinerary-templates";
    import {SLEEP_LAST_ITEM} from "./itinerary-templates";
    import {ITEM_TYPE_SLEEP_MID} from "./itinerary-templates";
    import {ITEM_TYPE_SLEEP_LAST} from "./itinerary-templates";
    import {ITEM_TYPE_CAR_RENTAL} from "./itinerary-templates";
    import {CAR_RENTAL_LAST_ITEM} from "./itinerary-templates";
    import {CAR_RENTAL_MID_ITEM} from "./itinerary-templates";
    import {ITEM_TYPE_CAR_RENTAL_MID} from "./itinerary-templates";
    import {ITEM_TYPE_CAR_RENTAL_LAST} from "./itinerary-templates";
    import {autosaveMixin} from "./item/item-mixins";
    import {SECTION_TYPE_OTHER, SECTION_TYPE_DAY} from "./itinerary-templates";
    import TripDates from "./item/TripDates";
    import {TOUR_COVER_SECTION} from "./itinerary-templates";
    import {SECTION_TYPE_COVER} from "./itinerary-templates";
    import ReorderSectionItemsModal from "./modal/ReorderSectionItemsModal";
    import SaveSectionToLibraryModal from "./modal/SaveToLibraryModal";
    import {refactorSectionsWithNewDates} from "./itinerary-templates";
    import {ITEM_TYPE_POI} from "./itinerary-templates";
    import {ITEM_TYPE_FLIGHT} from "./itinerary-templates";
    import BuilderAddDaySectionModal from "./modal/BuilderAddDaySectionModal";
    import LoadSectionFromLibraryModal from "./modal/LoadSectionFromLibraryModal";
    import {DAY_SECTION} from "./itinerary-templates";
    import {getParentItemById} from "./itinerary-templates";
    import {ITINERARY_CURRENT_VERSION} from "./itinerary-templates";
    import {getSectionForDayNum} from "./itinerary-templates";
    import {getDayNumForDate} from "./itinerary-templates";

    export default {
        components: {
            ReorderSectionItemsModal,
            TripDates,
            draggable,
            vButton,
            ChangeDatesModal,
            BuilderAddItemModal,
            BuilderAddDaySectionModal,
            TripCover,
            TripActivity,
            TripCarRental,
            TripCarRentalMid,
            TripCarRentalLast,
            TripClientInfo,
            TripCompanyInfo,
            TripDescription,
            TripDestinationInfo,
            TripGenericItem,
            TripFlight,
            TripHighlights,
            TripRatings,
            TripUrl,
            TripSoundcloud,
            TripVideo,
            TripMeal,
            TripPoi,
            TripPlace,
            TripSleep,
            TripSleepNight,
            TripSleepCheckout,
            TripTourName,
            TripTransportation,
            TripKnowBeforeGo,
            TripPrice,
            TripWhatsIncluded,
            TripNextSteps,
            TripCancelationPolicy,
            TripCarousel,
            TripFaq,
            TripAudio,
            TripVideoFile,
            SaveSectionToLibraryModal,
            LoadSectionFromLibraryModal,
        },
        mixins: [
            autosaveMixin,
        ],
        props: {
            itData: {
                type: Object,
                required: true,
            },
            undoIndex: {
                type: Number,
                required: true,
            },
            historySize: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                SECTION_TYPE_DAY: SECTION_TYPE_DAY,

                loading: false,

                activeSectionId: null,
                activeSectionType: null,
                activeSectionNext: null,
                //activeSection: null,

                showAddItemModal: false,
                addItemTop: false,

                showChangeDatesModal: false,
                changeDatesItem: {},

                showReorderModal: false,

                showAddDaySectionModal: false,
                showSetNameModal: false,
                showLoadSectionFromLibrary: false,
            }
        },
        computed: {
            sectionList() {
                if (this.itData.tripData !== undefined && this.itData.tripData !== null) {
                    return this.itData.tripData.sections;
                } else {
                    return [];
                }
            },
            activeSection() {
                const self = this;
                if (this.activeSectionId !== null) {
                    let section = this.sectionList.find(section => {
                        return section.id === self.activeSectionId;
                    });
                    if (section !== undefined) {
                        return section;
                    }
                }
                return {}; // podia ser null, e' so para o reorder modal nao se queixar
            },
            hasCoverSection() {
                // const self = this;
                if (this.sectionList !== undefined && this.sectionList !== null) {
                    let section = this.sectionList.find(section => {
                        return section.type === SECTION_TYPE_COVER;
                    });
                    if (section !== undefined) {
                        return section;
                    }
                }
                return false;
            },
            droppable() {
                if (this.activeSection) {
                        return !(this.activeSection.type === SECTION_TYPE_COVER || this.activeSection.type === SECTION_TYPE_OTHER);
                }
                return true;
            },
            isCover() {
                if (this.activeSection) {
                        return this.activeSection.type === SECTION_TYPE_COVER;
                }
                return false;
            },
            hasAllDays() {
                // const self = this;
                if (this.sectionList !== undefined && this.sectionList !== null) {
                    let count = 0;
                    this.sectionList.forEach(section => {
                        if (section.type === SECTION_TYPE_DAY) {
                            count++;
                        }
                    });

                    if (this.itData.numDays) {
                        return count === this.itData.numDays;
                    } else {
                        //TODO remover isto mais tarde
                        let startMoment = parseMoment(this.itData.startDate, this.itData.dateFormat);
                        let endMoment = parseMoment(this.itData.endDate, this.itData.dateFormat);

                        const dayCount = endMoment.diff(startMoment, 'days') + 1;

                        // console.log("### DAYS => " + dayCount);
                        // console.log("### DAY COUNT => " + count);
                        return count === dayCount;
                    }
                }
                return false;
            }
        },
        mounted() {
            const self = this;

            itineraryEventBus.$on('update-dates', () => {
                self.updateItineraryDates();
            });
            itineraryEventBus.$on('details-loaded', () => {
                // console.log("### SECTIONS => " + self.sectionList.length);
                self.fixOrphans();
                if (self.sectionList.length === 0) {
                    self.generateDefaultItinerary();
                } else {
                    self.openSection(self.sectionList[0]);
                }
            });
            itineraryEventBus.$on('open-changedates-modal', (item) => {
                self.changeDatesItem = item;
                self.showChangeDatesModal = true;
            });
            /*itineraryEventBus.$on('open-moveitem-modal', (item) => {
                self.moveItem = item;
                self.showMoveItemModal = true;
            });*/
        },
        beforeDestroy() {
            itineraryEventBus.$off('update-dates');
            itineraryEventBus.$off('details-loaded');
            itineraryEventBus.$off('open-changedates-modal');
        },
        watch: {
        },
        methods: {
            fixDates(num = 2) {
                const ndiff = (num < 2 ? 2 : num)-1;
                const startMoment = parseMoment(this.itData.startDate, this.itData.dateFormat);
                this.itData.endDate = formatDate(moment(startMoment).add(ndiff, 'day'), this.itData.dateFormat);
            },
            fixOrphans() {
                const sections = this.itData.tripData.sections;
                sections.forEach(section => {
                    let toRemove = [];
                    section.items.forEach(item => {
                        if (item.parentId) {
                            if (getParentItemById(sections, item.parentId) === null) {
                                toRemove.push(item)
                            }
                        }
                    });

                    toRemove.forEach(item => {
                        const idx = section.items.findIndex(itemTmp => {
                            return item.id === itemTmp.id;
                        });
                        if (idx !== -1) {
                            // console.log("fixOrphans => REMOVING ITEM ID=" + item.id + " TYPE=" + item.type + " from section => " + section.title);
                            section.items.splice(idx, 1);
                        }
                    })
                });


            },
            getShortSectionTitle(section) {
                if (section.type !== SECTION_TYPE_DAY) {
                    return section.title;
                } else if (section.isCustomTabName && section.customTabName) {
                    return section.customTabName;
                } else {
                    return this.$t("v2.Itinerary.defaults.DAY") + " " + section.dayNum;
                }
            },
            sectionHasFlight(section) {
                if (section.type !== SECTION_TYPE_DAY) {
                    return false;
                }
                let index = section.items.findIndex((item) => {
                    return item.type === ITEM_TYPE_FLIGHT;
                });
                return index !== -1;

            },
            sectionHasSleep(section) {
                if (section.type !== SECTION_TYPE_DAY) {
                    return false;
                }
                let index = section.items.findIndex((item) => {
                    return item.type === ITEM_TYPE_SLEEP;
                });
                return index !== -1;

            },
            sectionHasSleepMid(section) {
                if (section.type !== SECTION_TYPE_DAY) {
                    return false;
                }
                let index = section.items.findIndex((item) => {
                    return item.type === ITEM_TYPE_SLEEP_MID;
                });
                return index !== -1;

            },
            sectionHasSleepCheckout(section) {
                if (section.type !== SECTION_TYPE_DAY) {
                    return false;
                }
                let index = section.items.findIndex((item) => {
                    return item.type === ITEM_TYPE_SLEEP_LAST;
                });
                return index !== -1;

            },
            sectionHasRentalcar(section) {
                if (section.type !== SECTION_TYPE_DAY) {
                    return false;
                }
                let index = section.items.findIndex((item) => {
                    return item.type === ITEM_TYPE_CAR_RENTAL_MID;
                });
                return index !== -1;

            },
            sectionHasRentalcarPickup(section) {
                if (section.type !== SECTION_TYPE_DAY) {
                    return false;
                }
                let index = section.items.findIndex((item) => {
                    return item.type === ITEM_TYPE_CAR_RENTAL;
                });
                return index !== -1;

            },
            sectionHasRentalcarReturn(section) {
                if (section.type !== SECTION_TYPE_DAY) {
                    return false;
                }
                let index = section.items.findIndex((item) => {
                    return item.type === ITEM_TYPE_CAR_RENTAL_LAST;
                });
                return index !== -1;

            },
            generateDefaultItinerary() {
                // cria o itinerario default se estiver vazio
                const empty = this.itData.tripData.sections.length === 0;
                if (empty && this.itData.numDays) {
                    let newSectionList = [];
                    newSectionList.push(template.TOUR_COVER_SECTION());
                    newSectionList.push(template.TOUR_INTRO_SECTION());

                    let startMoment = null;
                    if (this.itData.startDate && this.itData.dateFormat) {
                        startMoment = parseMoment(this.itData.startDate, this.itData.dateFormat);
                    }

                    let dayIndex = 1;
                    while (dayIndex <= this.itData.numDays) {
                        if (startMoment) {
                            newSectionList.push(template.DAY_SECTION(dayIndex, startMoment, this.itData.dateFormat));
                            startMoment.add(1, 'days');
                        } else {
                            newSectionList.push(template.DAY_SECTION(dayIndex));
                        }
                        dayIndex++;
                    }

                    this.itData.tripData.version = ITINERARY_CURRENT_VERSION;

                    // if (empty) {
                    //  newSectionList.push(template.PRICES_CONDITIONS_SECTION());
                    // }

                    this.itData.tripData.sections = newSectionList;
                    this.emitAutosave();

                    this.activeSectionId = this.sectionList[0].id;
                    this.activeSectionType = this.sectionList[0].type;

                    if (this.sectionList[1]) {
                        this.activeSectionNext = this.sectionList[1].type;
                    }

                } /*else if (this.itData.startDate !== undefined &&
                    this.itData.startDate !== null &&
                    this.itData.startDate !== '' &&
                    empty
                ) {
                    let newSectionList = [];
                    newSectionList.push(template.TOUR_COVER_SECTION());
                    newSectionList.push(template.TOUR_INTRO_SECTION());

                    let startMoment = parseMoment(this.itData.startDate, this.itData.dateFormat);
                    let endMoment = parseMoment(this.itData.endDate, this.itData.dateFormat);

                    // console.log("### START => " + this.itData.startDate);
                    // console.log("### END => " + this.itData.endDate);

                    // let firstSectionsLastIndex = newSectionList.length + 1;
                    let dayIndex = 1;

                    while (startMoment.isSameOrBefore(endMoment)) {
                        newSectionList.push(template.DAY_SECTION(dayIndex, startMoment, this.itData.dateFormat));
                        dayIndex++;

                        startMoment.add(1, 'days');
                    }

                    if (empty) {
                        newSectionList.push(template.PRICES_CONDITIONS_SECTION());
                    }

                    this.itData.tripData.sections = newSectionList;
                    this.emitAutosave();

                    this.activeSectionId = this.sectionList[0].id;
                }*/
            },
            updateItineraryDates() {
                this.itData.tripData.sections = refactorSectionsWithNewDates(
                    this.sectionList,
                    this.itData.startDate,
                    this.itData.endDate,
                    this.itData.dateFormat,
                    this.itData.numDays
                );
                this.emitAutosave();

                this.activeSectionId = this.sectionList[0].id;
                this.activeSectionType = this.sectionList[0].type;

                if (this.sectionList[1]) {
                    this.activeSectionNext = this.sectionList[1].type;
                }
            },
            addCoverSection() {
                this.itData.tripData.sections.unshift(
                    TOUR_COVER_SECTION()
                );
                this.emitAutosave();
            },
            addTopSection() {
                let self = this;
                let newSection = {
                    "id": generateUUID(),
                    "title": this.$t("Itinerary.builder.new_section"),
                    "type": SECTION_TYPE_OTHER,
                    "date": null,
                    items: [],
                };

                let showWarningForDate = false;
                let isSelection = false;

                let dayIndex = 0;
                let i;
                for (i = 0; i < this.sectionList.length; i++) {
                    if ( this.sectionList[i].id === this.activeSectionId ) {
                        dayIndex = i;
                    }
                }

                if ( this.activeSectionType ===  this.activeSectionNext) {
                    // USE SAME
                    if (this.activeSectionType === 'DAY') {
                        newSection = {
                            "id": generateUUID(),
                            "title": this.$t("Itinerary.builder.new_day"),
                            "type": this.activeSectionType,
                            "dayNum" : this.activeSection.dayNum + 1,
                            "showPdfTitle": this.activeSection.showPdfTitle,
                            "date": null,
                            items: [],
                        };

                        if ( this.newDayShouldHaveHotel() ) {
                            newSection.items.push(
                                {
                                    id: generateUUID(),
                                    parentId: this.activeHotelForSelectedDate(),
                                    type: "SLEEP_MID"
                                }
                            );
                        }

                        let active = false;
                        let iii = 0;
                        for (iii = 0; iii < this.sectionList.length; iii++) {
                            if ( active ) {
                                if ( this.sectionList[iii].dayNum ) {
                                    this.sectionList[iii].dayNum = this.sectionList[iii].dayNum + 1;
                                }
                            }
                            if ( this.sectionList[iii].id === this.activeSectionId ) {
                                active = true;
                            }
                        }

                        this.itData.numDays = this.itData.numDays + 1;
                        showWarningForDate = true;
                    } else {
                        newSection = {
                            "id": generateUUID(),
                            "title": this.$t("Itinerary.builder.new_section"),
                            "type": this.activeSectionType,
                            "showPdfTitle": this.activeSection.showPdfTitle,
                            "date": null,
                            items: [],
                        };
                    }
                } else {

                    if (this.activeSectionType === 'COVER' && this.activeSectionNext === 'OTHER') {
                        newSection = {
                            "id": generateUUID(),
                            "title": this.$t("Itinerary.builder.new_section"),
                            "type": SECTION_TYPE_OTHER,
                            "showPdfTitle": this.activeSection.showPdfTitle,
                            "date": null,
                            items: [],
                        };
                    } else {

                        if (this.activeSectionType !== this.activeSectionNext) {
                            isSelection = true;
                            bootbox.prompt({
                                title: this.$t("Itinerary.builder.new_section"),
                                inputType: 'select',
                                inputOptions: [
                                    {
                                        text: this.$t("Itinerary.builder.new_day"),
                                        value: 'DAY',
                                    },
                                    {
                                        text: this.$t("Itinerary.builder.new_general_section"),
                                        value: 'OTHER',
                                    }
                                ],
                                callback: function (result) {
                                    
                                    if (result === null) {
                                        return;
                                    }

                                    if (result === 'DAY') {

                                        let daynum = 1 ;
                                        if (self.activeSection.dayNum)
                                            daynum = self.activeSection.dayNum + 1;

                                        newSection = {
                                            "id": generateUUID(),
                                            "title": self.$t("Itinerary.builder.new_day"),
                                            "type": 'DAY',
                                            "dayNum" : daynum,
                                            "showPdfTitle": self.activeSection.showPdfTitle,
                                            "date": null,
                                            items: [],
                                        };

                                        let active = false;
                                        let iii = 0;
                                        for (iii = 0; iii < self.sectionList.length; iii++) {
                                            if ( active ) {
                                                if ( self.sectionList[iii].dayNum ) {
                                                    // TODO STUFF WITH DATES ON HOTEL
                                                    self.sectionList[iii].items.forEach(item => {
                                                        if(item.type === ITEM_TYPE_SLEEP) {
                                                            item.startDay = item.startDay + 1;
                                                            item.endDay = item.endDay + 1;
                                                        }
                                                    });

                                                    self.sectionList[iii].dayNum = self.sectionList[iii].dayNum + 1;
                                                }
                                            }
                                            if ( self.sectionList[iii].id === self.activeSectionId ) {
                                                active = true;
                                            }
                                        }

                                        self.itData.numDays = self.itData.numDays + 1;
                                        showWarningForDate = true;
                                    } else {
                                        newSection = {
                                            "id": generateUUID(),
                                            "title": self.$t("Itinerary.builder.new_section"),
                                            "type": SECTION_TYPE_OTHER,
                                            "showPdfTitle": self.activeSection.showPdfTitle,
                                            "date": null,
                                            items: [],
                                        };
                                    }

                                    if (self.hasCoverSection) {
                                        self.itData.tripData.sections.splice(dayIndex + 1, 0, newSection);
                                    } else {
                                        self.itData.tripData.sections.unshift();
                                    }
                                    self.emitAutosave();

                                    if (showWarningForDate) {
                                        showHelpDialog(
                                            self.$t("Itinerary.builder.new_day"),
                                            self.$t("Itinerary.builder.new_day_message")
                                        );
                                    }
                                }
                            });
                        }
                    }
                }

                if (!isSelection) {
                    if (this.hasCoverSection) {
                        this.itData.tripData.sections.splice(dayIndex + 1, 0, newSection);
                    } else {
                        this.itData.tripData.sections.unshift();
                    }
                    this.emitAutosave();

                    if (showWarningForDate) {
                        showHelpDialog(
                            this.$t("Itinerary.builder.new_day"),
                            this.$t("Itinerary.builder.new_day_message")
                        );
                    }
                }
            },
            addAdditionalDaySection() {
                const days = this.sectionList.filter(item => item.type === 'DAY');
                this.fixDates(days.length + 1);
                this.itData.numDays = days.length + 1;
                let num  = days.length ? days[days.length - 1].dayNum : 1;
                if(this.activeSection && this.activeSection.type === 'DAY') {
                    num = this.activeSection.dayNum;
                }
                this.doAddDaySection(num+1)
            },
            addDaySection() {
                this.showAddDaySectionModal = true;
            },
            doAddDaySection(dayNum) {
                this.showAddDaySectionModal = false;

                let dayIndex = 0;
                this.sectionList.some(section => {
                    if (section.type === SECTION_TYPE_DAY && section.dayNum -1 === dayNum) {
                        // e' o dia antes
                        return true;
                    }
                    dayIndex++;
                });

                let newSection;
                if (this.itData.startDate && this.itData.dateFormat) {
                    const dateMoment = getDateMomentForDayNum(dayNum, this.itData.startDate, this.itData.dateFormat);
                    newSection = DAY_SECTION(dayNum, dateMoment, this.itData.dateFormat);
                } else {
                    newSection = DAY_SECTION(dayNum);
                }

                this.sectionList.forEach(section => {
                    section.items.forEach(item => {
                        const itemStartDay = item.startDay;
                        const itemEndDay = item.endDay;
                        if (item.type === ITEM_TYPE_SLEEP) {
                            if (itemStartDay < dayNum && itemEndDay >= dayNum) {
                                // existe sleep item que apanha este dia, adiciona sleep MID OU LAST a esta seccao
                                if (itemEndDay === dayNum) {
                                    // se for ultimo dia, adiciona ao inicio do array.
                                    // Se primeiro item da seccao for poi, adicionao na segunda posicao
                                    if (section.items.length > 0 && section.items[0].type === ITEM_TYPE_POI) {
                                        newSection.items.splice(1, 0, SLEEP_LAST_ITEM(item.id));
                                    } else {
                                        newSection.items.unshift(SLEEP_LAST_ITEM(item.id));
                                    }
                                } else {
                                    // se NAO for ultimo dia, adiciona ao fim do array
                                    newSection.items.push(SLEEP_MID_ITEM(item.id))
                                }
                            }
                        } else if (item.type === ITEM_TYPE_CAR_RENTAL) {
                            if (itemStartDay < dayNum && itemEndDay >= dayNum) {
                                // existe sleep item que apanha este dia, adiciona sleep MID OU LAST a esta seccao
                                if (itemEndDay === dayNum) {
                                    // se for ultimo dia
                                    if (newSection.items.length > 0 && section.items[0].type === ITEM_TYPE_POI) {
                                        newSection.items.splice(1, 0, CAR_RENTAL_LAST_ITEM(item.id));
                                    } else {
                                        newSection.items.unshift(CAR_RENTAL_LAST_ITEM(item.id));
                                    }
                                } else {
                                    // se NAO for ultimo dia
                                    if (newSection.items.length > 0 && section.items[0].type === ITEM_TYPE_POI) {
                                        newSection.items.splice(1, 0, CAR_RENTAL_MID_ITEM(item.id));
                                    } else {
                                        newSection.items.unshift(CAR_RENTAL_MID_ITEM(item.id));
                                    }
                                }
                            }
                        }
                    });
                });

                this.itData.tripData.sections.splice(dayIndex, 0, newSection);

                this.emitAutosave();
            },
            addBottomSection() {
                this.itData.tripData.sections.push({
                    "id": generateUUID(),
                    "title": this.$t("Itinerary.builder.new_end_section"),
                    "type": SECTION_TYPE_OTHER,
                    "date": null,
                    items: [],
                });
                this.emitAutosave();
            },
            openSection(section) {
                this.activeSectionId = section.id;
                this.activeSectionType = section.type;

                let next = null;
                let i;
                for (i = 0; i < this.sectionList.length; i++) {
                  if (this.sectionList[i].id === section.id) {
                      if (this.sectionList[i+1]) {
                          next = this.sectionList[i+1].type;
                      }
                  }
                }
                this.activeSectionNext = next;
            },
            deleteActiveSection() {
                const self = this;
                showCustomDialog(
                    this.$t("Itinerary.builder.are_you_sure"),
                    this.$t("Itinerary.builder.remove_message"),
                    this.$t("Itinerary.builder.remove_button"),
                    "btn-danger",
                    function() {
                        if (self.activeSectionId !== null) {
                            let sectionIndex = self.itData.tripData.sections.findIndex(function(i){
                                return i.id === self.activeSectionId;
                            });


                            if (self.activeSectionType === 'DAY') {
                                let active = false;
                                let iii = 0;
                                for (iii = 0; iii < self.sectionList.length; iii++) {
                                    if (active) {
                                        if (self.sectionList[iii].dayNum) {
                                            self.sectionList[iii].items.forEach(item => {
                                                if(item.type === ITEM_TYPE_SLEEP) {
                                                    item.startDay = item.startDay - 1;
                                                    item.endDay = item.endDay - 1;
                                                }
                                            });

                                            self.sectionList[iii].dayNum = self.sectionList[iii].dayNum - 1;
                                        }
                                    }
                                    if (self.sectionList[iii].id === self.activeSectionId) {
                                        active = true;
                                    }
                                }
                            }

                            self.itData.tripData.sections.splice(sectionIndex, 1);
                            if (self.activeSectionType === 'DAY') {
                                self.itData.numDays = self.itData.numDays - 1;
                            }
                            sectionIndex--;
                            if (sectionIndex >= 0) {
                                self.activeSectionId = self.itData.tripData.sections[sectionIndex].id;
                                self.activeSectionType = self.itData.tripData.sections[sectionIndex].type;
                                if (self.itData.tripData.sections[sectionIndex+1]) {
                                    self.activeSectionNext = self.itData.tripData.sections[sectionIndex+1].type;
                                }

                            } else if (self.itData.tripData.sections.length > 0) {
                                self.activeSectionId = self.itData.tripData.sections[0].id;
                                self.activeSectionType = self.itData.tripData.sections[0].type;
                                if (self.itData.tripData.sections[sectionIndex+1]) {
                                    self.activeSectionNext = self.itData.tripData.sections[sectionIndex+1].type;
                                }
                            } else {
                                self.activeSectionId = null;
                                self.activeSectionType = null;
                                self.activeSectionNext = null;
                            }
                            self.emitAutosave();
                        }
                    }
                );
            },
            openAddItem(top) {
                this.addItemTop = top;
                this.showAddItemModal = true;
            },
            addItem(item) {
                if (item.type === ITEM_TYPE_SLEEP) {
                    // adiciona um item por dia nas varias seccoes

                    let dayIter = item.startDay;

                    // adiciona item original do hotel
                    let section = getSectionForDayNum(this.sectionList, dayIter);
                    if (section) {
                        section.items.push(item);

                        dayIter++;
                        while (dayIter <= item.endDay) {
                            let section = getSectionForDayNum(this.sectionList, dayIter);
                            if (section) {
                                if (dayIter === item.endDay) {
                                    // se for ultimo dia, adiciona ao inicio do array.
                                    // Se primeiro item da seccao for poi, adicionao na segunda posicao
                                    if (section.items.length > 0 && section.items[0].type === ITEM_TYPE_POI) {
                                        section.items.splice(1, 0, SLEEP_LAST_ITEM(item.id));
                                    } else {
                                        section.items.unshift(SLEEP_LAST_ITEM(item.id));
                                    }
                                } else {
                                    // se NAO for ultimo dia, adiciona ao fim do array
                                    section.items.push(SLEEP_MID_ITEM(item.id))
                                }
                            }
                            dayIter++;
                        }
                    } else {
                        showErrorDialog(
                            this.$t("Itinerary.builder.add_item_problem_title"),
                            this.$t("Itinerary.builder.add_item_problem_msg", {day: dayIter})
                        );
                    }
                } else if (item.type === ITEM_TYPE_CAR_RENTAL) {
                    // adiciona um item por dia nas varias seccoes

                    let dayIter = item.startDay;

                    // adiciona item original do car rental
                    let section = getSectionForDayNum(this.sectionList, dayIter);
                    if (section) {
                        section.items.push(item);

                        dayIter++;
                        while (dayIter <= item.endDay) {
                            let section = getSectionForDayNum(this.sectionList, dayIter);
                            if (section) {
                                if (dayIter === item.endDay) {
                                    // se for ultimo dia
                                    if (section.items.length > 0 && section.items[0].type === ITEM_TYPE_POI) {
                                        section.items.splice(1, 0, CAR_RENTAL_LAST_ITEM(item.id));
                                    } else {
                                        section.items.unshift(CAR_RENTAL_LAST_ITEM(item.id));
                                    }
                                } else {
                                    // se NAO for ultimo dia
                                    if (section.items.length > 0 && section.items[0].type === ITEM_TYPE_POI) {
                                        section.items.splice(1, 0, CAR_RENTAL_MID_ITEM(item.id));
                                    } else {
                                        section.items.unshift(CAR_RENTAL_MID_ITEM(item.id));
                                    }
                                }
                            }
                            dayIter++;
                        }
                    } else {
                        showErrorDialog(
                            this.$t("Itinerary.builder.add_item_problem_title"),
                            this.$t("Itinerary.builder.add_item_problem_msg", {day: dayIter})
                        );
                    }
                } else {
                    if (this.addItemTop) {
                        this.activeSection.items.unshift(item);
                    } else {
                        this.activeSection.items.push(item);
                    }
                }
                this.emitAutosave();
            },

            addSetion(section) {

                /*for ( i = 0 ; i < this.activeSection.items.length ; i++ ) {
                    this.activeSection.items
                }*/
                this.activeSection.items = [];

                let i = 0 ;
                for ( i = 0 ; i < section.data.items.length ; i++ ) {

                    if ( section.data.items[i].type ) {

                        if (section.data.items[i].type === ITEM_TYPE_SLEEP) {
                            this.activeSection.items[i] = section.data.items[i];

                            this.activeSection.items[i].id = generateUUID();
                            this.activeSection.items[i].startDate = '';
                            this.activeSection.items[i].endDate = '';
                        } else if (section.data.items[i].type === ITEM_TYPE_SLEEP_MID || section.data.items[i].type === ITEM_TYPE_SLEEP_MID ) {
                            // SKIP MIDDLE AND END FOR HOTEL !!!!!
                        } else {
                            this.activeSection.items[i] = section.data.items[i];
                            this.activeSection.items[i].id = generateUUID();
                        }

                    }   else {
                        this.activeSection.items[i] = section.data.items[i];
                        this.activeSection.items[i].id = generateUUID();
                    }
                }
                this.emitAutosave();
            },

            deleteItem(item) {
                let self = this;
                showCustomDialog(
                    this.$t("Itinerary.builder.are_you_sure"),
                    this.$t("Itinerary.builder.remove_item_message"),
                    this.$t("Itinerary.builder.remove_button"),
                    "btn-danger",
                    function() {
                        self.doDeleteItem(item);
                    }
                );
            },
            doDeleteItem(item) {
                const self = this;
                if (self.activeSection !== null) {
                    if (
                        item.type === ITEM_TYPE_SLEEP ||
                        item.type === ITEM_TYPE_SLEEP_MID ||
                        item.type === ITEM_TYPE_SLEEP_LAST ||
                        item.type === ITEM_TYPE_CAR_RENTAL ||
                        item.type === ITEM_TYPE_CAR_RENTAL_MID ||
                        item.type === ITEM_TYPE_CAR_RENTAL_LAST
                    ) {
                        //TODO isto nao esta a funcionar bem, validar
                        let parentId;
                        if (item.type === ITEM_TYPE_SLEEP || item.type === ITEM_TYPE_CAR_RENTAL) {
                            parentId = item.id;
                        } else {
                            parentId = item.parentId;
                        }

                        self.sectionList.forEach((section) => {
                            // procura e apaga todos os que tenham o mesmo parentId
                            index = -1;
                            do {
                                let index = section.items.findIndex((itemIter) => {
                                    return itemIter.parentId !== undefined &&
                                        itemIter.parentId !== null &&
                                        itemIter.parentId === parentId;
                                });
                                if (index !== -1) {
                                    section.items.splice(index, 1);
                                }
                            } while(index !== -1);

                            // procura e apaga o parent
                            let index = section.items.findIndex((itemIter) => {
                                return itemIter.id === parentId;
                            });
                            if (index !== -1) {
                                section.items.splice(index, 1);
                            }
                        });
                    } else {
                        self.activeSection.items.splice(self.activeSection.items.findIndex(function (i) {
                            return i.id === item.id;
                        }), 1);
                    }
                    self.emitAutosave();
                }
            },
            updateItemDates(newDates) {
                // newDates.startDate
                // newDates.endDate
                // console.log("### updateItemDates => " + JSON.stringify(newDates));
                // cria copia do item original
                const newItem = copyData(this.changeDatesItem);
                newItem.startDay = getDayNumForDate(newDates.startDate, this.itData.startDate, this.itData.dateFormat);
                newItem.endDay = getDayNumForDate(newDates.endDate, this.itData.startDate, this.itData.dateFormat);

                //TODO possivelmente optimizar isto
                // apaga o item original
                this.doDeleteItem(this.changeDatesItem);
                // adiciona o novo
                this.addItem(newItem);
                this.changeDatesItem = {};
                showSuccess(this.$t("Itinerary.builder.dates_updated"));
            },
            updateItemDays(newDays) {
                // newDates.startDate
                // newDates.endDate
                // console.log("### updateItemDates => " + JSON.stringify(newDates));
                // cria copia do item original
                const newItem = copyData(this.changeDatesItem);
                newItem.startDay = newDays.startDay;
                newItem.endDay = newDays.endDay;

                //TODO possivelmente optimizar isto
                // apaga o item original
                this.doDeleteItem(this.changeDatesItem);
                // adiciona o novo
                this.addItem(newItem);
                this.changeDatesItem = {};
                showSuccess(this.$t("Itinerary.builder.dates_updated"));
            },
            changeItemSection(dataObj) {
                // dataObj.item => item a mover
                // dataObj.newSectionId => id da seccao nova
                // console.log("### changeItemSection => " + dataObj.newSectionId);
                // cria copia do item original
                const newItem = copyData(dataObj.item);

                // apaga o item original
                this.doDeleteItem(dataObj.item);

                // adiciona o novo
                const newSection = this.sectionList.find(section => {
                    return section.id === dataObj.newSectionId;
                });
                newSection.items.unshift(newItem);
                this.emitAutosave();
                showSuccess(
                    this.$t("Itinerary.builder.item_moved")
                );
            },
            newDayShouldHaveHotel() {
                if ( this.activeSectionType === 'DAY') {
                    let i;
                    for (i = 0; i < this.activeSection.items.length; i++) {
                        if ( this.activeSection.items[i].type === 'SLEEP' ||  this.activeSection.items[i].type === 'SLEEP_MID') {
                            return true;
                        }
                    }

                }
                return false;
            },
            activeHotelForSelectedDate() {
                if ( this.activeSectionType === 'DAY') {
                    let i;
                    for (i = 0; i < this.activeSection.items.length; i++) {
                        if ( this.activeSection.items[i].type === 'SLEEP' ) {
                            return this.activeSection.items[i].id;
                        }
                        if ( this.activeSection.items[i].type === 'SLEEP_MID') {
                            return this.activeSection.items[i].parentId;
                        }
                    }

                }
                return null;
            }
        }
    }
</script>

<style scoped>
    .list-item-summernote:hover {
        z-index: auto;
    }


    .itinerary-bar {
        position: -webkit-sticky; /* Required for Safari */
        position:sticky;
        top:0;
        width:100%;
        z-index: 2;

        background-color: #f8f9fa;
        border: 1px solid #dee2e6;
    }

    .itinerary-section-list {
        position: sticky;
        top: 66px;
        height: calc(100vh - 80px);
        overflow-y: scroll;
    }

    .itinerary-section-list::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        /*border-radius: 10px;*/
        border-radius: 5px;
        background-color: white;
        /*background-color: #F5FFFF;*/
    }

    .itinerary-section-list::-webkit-scrollbar
    {
        width: 12px;
        background-color: white;
    }

    .itinerary-section-list::-webkit-scrollbar-thumb
    {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: lightgray;
    }
</style>
